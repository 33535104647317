const { useEffect, useState } = wp.element;

function AllCategoriesService(url) {
  const [data, setData] = useState(null);
  useEffect(() => {
    async function loadData() {
      const response = await fetch(url);
      if (!response.ok) {
        // oups! something went wrong
        return;
      }

      const categories = await response.json();
      setData(categories);
    }

    loadData();
  }, [url]);
  return data;
}
export default AllCategoriesService;
