import AllCategoriesService from "../../wp-rest/categories";
const { render } = wp.element;

function CategoryFilterComponent(props) {
  const categories = AllCategoriesService("/wp-json/wp/v2/categories");
  let handleChange = (event) => {
    let selectedCategory = event.target.value;
    props.allPostsCatgeoryCallback(selectedCategory);
  };
  return (
    <select onChange={handleChange}>
      <option value="">Select a Category</option>
      {categories &&
        categories.map((category, index) => (
          <option value={category.id} key={index}>
            {category.name}
          </option>
        ))}
    </select>
  );
}
export default CategoryFilterComponent;
