import AllPostsService from "../../wp-rest/posts";
import CategoryFilterComponent from "../posts-filter/filter-dropdown";
import "./display-all-posts.scss";
const { useState } = wp.element;

function AllPostsComponent() {
  const [category, setCategory] = useState("");
  let categoryCallback = (newCategory) => {
    setCategory(newCategory);
  };
  let postsURL = "/wp-json/wp/v2/posts?_embed&categories=" + category;

  const posts = AllPostsService(postsURL);
  return (
    <div className="container">
      <CategoryFilterComponent allPostsCatgeoryCallback={categoryCallback} />
      <div className="row">
        {posts &&
          posts.map((post, index) => (
            <div className="col-4" key={index}>
              <div className="featured-image">
                <img src={post._embedded["wp:featuredmedia"][0].source_url} />
              </div>
              <h2>{post.title.rendered}</h2>
              <p dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} />
            </div>
          ))}
      </div>
    </div>
  );
}
export default AllPostsComponent;
